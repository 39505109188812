@font-face {
  font-family: CaptureIt;
  src: url("./fonts/CaptureIt.ttf");
}

body {
  background: url("./assets/BG.png") no-repeat center center fixed;
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDialogTitle-root {
  background: #b5763f !important;
};

.MuiPaper-root {
  background: #1f1c15 !important;
}